h1,h2,h3,h4,h5,h6 {
    line-height: 1.25em;
    margin-bottom: 1rem;
    font-family: $display;
    font-weight: normal;
    text-align: center;
}

h1,h2 { 
    font-family: $display;
}

h1 { font-size: 2.5rem;     }
h2 { font-size: 2.00rem;     }
h3 { font-size: 1.75rem;     }
h4 { font-size: 1.50rem;     }
h5 { font-size: 1.25rem;     }
h6 { font-size: 1em;     }

@media (max-width: 800px) { 
    h1 { font-size: 2.5rem; }
    h2 { font-size: 2rem;   }
    h3 { font-size: 1.75rem;     }
    h4 { font-size: 1.5rem;     }
}

hr { 
    margin-top: 4rem;
    margin-bottom: 4rem;
    border-bottom: 1px solid $shadow;
}

p,dl,ul,ol,pre,table { 
    font-size: 1rem;
}

p,dl,dt,dd {
    line-height: 1.75em;
    margin-bottom: 1.5rem;
    a { 
        color: currentColor;
    }
    em { 
        font-style: italic;
    }
    abbr { 
        border-bottom: 1px solid $shadow;
        &:hover { border-color: currentColor; }
    }
    code {
        padding: .125em .33em;
        margin-right: .125rem;
    }
    del { opacity: .7; }
}

code { 
    font-family: monospace;
    var { color: $main; font-style: italic;}
}

dl,ul,ol,pre { 
    margin-bottom: 2rem;
}

pre { 
    background: $black;
    color: $white;
    padding: 2rem;
    border-radius: 4px;    
}

dt { 
    margin-bottom: .5rem;
    text-decoration: underline;
}

dd { 
    margin-bottom: 2rem;
    margin-left: 1rem;
    color: $main;    
    font-style: italic;
    &:last-of-type { margin-bottom: 0; }
    &:before { 
        content: '>';
        margin-left: -1rem;
        margin-right: .5rem;
    }
} 

pre { 
    line-height: 1.5rem;
}

main ul > li { list-style: disc; }
main ol > li { list-style: decimal-leading-zero; }

main ul, main ol {
    list-style: circle;
    li {
        text-align: left;
        margin-left: 2.5rem;
        line-height: 1.5rem;
        margin-bottom: .25rem;
        &:last-of-type { margin-bottom: 0; }
        > ol,ul {
            margin-top: .25rem;
            margin-bottom: 0;
        }
    }
}

table { 
    width: 100%;
    margin-bottom: 2rem;
}

tr {
    &:nth-of-type(2n) { 
        background: $tint;
    }
}

td,th { 
    border: 1px solid $main;
    text-align: left;
    padding: 1rem; 
}

blockquote,q { 

    width: calc(100% + 4rem);
    margin-left: -2rem;

    padding: 2rem 2rem .5rem 1.9rem;
    margin-bottom: 2rem;

    font-size: 1.25rem;
    line-height: 1.5em;
    font-style: italic;
    
    border-left: .125em solid $main;

    cite { 
        display: block;
        margin-top: 1rem;
        
        a { 
            text-decoration: none; 
            color: $main; 
        }

    }

}

/* 
XXXXXXXXXXXXXXXX
EXCITING FORM STYLES
XXXXXXXXXXXXXXXX
*/

legend { 
    font-family: $display;
    font-size: 1.25em;
    font-weight: bold;
    margin-bottom: 2rem;
}

label { 
    display: block;
    font-size: .85rem;
    cursor: pointer;
    &:hover { 
        color: $main;
        + input { 
            background: #FFF;
        }
    }
    &:active,:focus { 
        color: $main;
    }
}

input,textarea,select { 
    display: block;
    padding: .5rem;
    margin: .75rem 0 0 0;
    font-family: inherit;
    font-size: 1rem;
}

select, [type='text'],[type='url'],[type='email'],[type='password'],
[type='tel'],[type='search'],[type='number'],[type='date'],[type='month'],[type='week'],[type='datetime'],[type='datetime-local'],textarea { 
    width: 100%;    
    background: $tint;
    border: 1px solid $shadow;
    outline: 0;
    &:focus,:active { 
        background: #FFF;
        border: 1px solid $main;
    }
    &::-moz-placeholder { color: $main; }
    &::-webkit-input-placeholder { color: $main; }
    &:-ms-input-placeholder { color: $main; }  
    &~input { color: $main; }  
}

[type="radio"],[type="checkbox"] { 
    display: inline-block;
    position: relative;
    margin: 0 .5rem 0 0;
    &:before { 
        content: ' ';        
        position: absolute;
        top: -.125em; left: 0;
        height: 1em; width: 1em;
        border: 1px solid currentColor;
        border-radius: .25em;
        background: $white;
    }
    &:checked { 
        color: #FFF;
        &:before { 
            content: "\2713";
            border-color: darken($main,30%);
            text-transform: lowercase;
            background: darken($main,30%);        
            text-align: center;
        }
    }
}

[type="color"] {
    height: 2rem;
    min-width: 3rem;
    border: 0; padding: 0;
    background: transparent;
}

[type="range"] {    
    -webkit-appearance: none;
    width: 100%;
    padding: 0;
    cursor: move;    
    background: transparent;
    &:focus,:active { 
        outline: 0;
    }
}

input[type=range]::-ms-track {
  width: 100%;
  padding: 0;
  background: transparent; 
  border-color: transparent;
  color: transparent;
}

@mixin sliderthumb { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 0; border-radius: 0;
    background: $main;
    height: 2rem; width: .75rem;
    &:hover { background: darken($main,20%); }
}

@mixin slidertrack {
    height: 2rem;
    background: $shadow;
    border: 1px solid $shadow;        
}

input[type=range]::-webkit-slider-thumb { @include sliderthumb; }
input[type=range]::-moz-range-thumb { @include sliderthumb; }
input[type=range]::-ms-thumb { @include sliderthumb; }

input[type=range]::-webkit-slider-runnable-track { @include slidertrack; }
input[type=range]::-moz-range-track { @include slidertrack; }
input[type=range]::-ms-track { @include slidertrack; }

input[type=range]::-ms-fill-lower { background: $shadow; }

// COMPONENTS
// Reusable styles for consistent elements

.btn,button,[type="button"],[type="submit"],[type="reset"] {
    align-self: flex-start; 
    display: inline-block;
    padding: .5rem .75em;
    text-decoration: none;

    font-size: 1em;

    color: $white;
    border: 1px solid var(--brand);
    background: $btn-colour;
    background: var(--brand);
    cursor: pointer;

    border-radius: .25em; 
    transition: ease-in-out,all,.2s;

    &:visited { 
        color: $white;
    }

    &:hover { 
        transform: scale(1.025);
        color: $white;
        box-shadow: 1px 2px 5px $shadow;
    }

    &.ghost {
        color: currentColor;
        border-color: currentColor; 
        background: transparent; 
        &:hover { 
            //background: $tint;
        }
    }
    &:disabled {
        color: lighten($main,35%);
        background: lighten($main,45%);
        border-color: $main;
        pointer-events: none;
    }

}

[type="reset"] {
    background: $main;
    color: $black;
    border-color: $main;
    &:hover {
        color: darken($main,45%);    
        background: lighten($main,10%);    
    }
}

.marquee { 
    width: 100%;
    margin: 0;
    padding: 3rem 2rem;
    font-size: 1.5em;
    font-weight: bold;
    text-align: center;
    color: $main;
}

.icon { 
    display: inline-block;
    width: 1em;
    height: 1em;
    margin-bottom: -.2em;
    margin-right: .25em;
    fill: currentColor;
}