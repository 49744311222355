//Typo

$base   : 20px; // base fontsize for the site 
                // flows on into grid spacing for consistency.

//Max width for a non-full width website
$full	: 1080px;

// Key Breakpoints
$sml	: 400px;
$tab    : 720px;
$med	: 1000px;
$lrg	: 1800px; // specifically for wider screens.

$font   : 'Public Sans', Helvetica, sans-serif; // General font used all over the shop
$display: 'Public Sans', Helvetica, sans-serif; // For headings mainly.

//Colours

$main : #343434;
$black : #141414;
$shadow : rgba($black, .25);
$tint : rgba($black, .05);
$bg : #FFFFFF;

$success : #00957a;
$warning : #ff8640;
$error   : #CC0000;

// THEMING VARS

$text-colour : $black;
$title-colour : $black;
$card-title-colour : $main;
$btn-colour : $main;
$btn-text-colour : $main;
$btn-hover-colour : darken( $main, 10% );

// Colours used in menus

$menuh : $black; // HOVER
$menua : $main; // ACTIVE

//CUSTOMS

$trans : ease, all, .2s;

body { --brand: #{$main}; }
.elevate { --brand: #00aeef; }
.thermalheart { --brand: #8dc63f; }
.vantage { --brand: #000000; }
.residential { --brand : #337577; }
.designer { --brand : #003063; }
.thermal { --brand : #d6982f; }
.specialty { --brand : #7c2353; }
.architectural { --brand : #028ba3; }
.commercial { --brand : #3b3251; }
.thermal-com { --brand : #669900; }
.framing { --brand : #a12116; }
.hardware { --brand : #afb1b3; }

// SECTION STYLES
$navbg : $white;
.bg-grey { background-color: #EFEFEF; }
.bg-half-grey { background: linear-gradient(#EFEFEF 0, #EFEFEF 50%, $white 50%); }
.bg-half-white { background: linear-gradient($white 0, $white 50%, #EFEFEF 50%); }

// BASIC UTILS

.flex-col { display: flex; flex-direction: column; }
.flex-row { display: flex; flex-direction: row; }